const {
  REACT_APP_API_BASE_URL,
  REACT_APP_API_KEY,
  REACT_APP_RECAPTCHA_SITE_KEY,
  REACT_APP_KHALTI_TEST_API_KEY: KHALTI_TEST_API_KEY,
  REACT_APP_MEDIA_BASE_URL = '',
  REACT_APP_RMIS_PAYMENT_URL,
} = process.env;

export const RMIS_PAYMENT_URL = REACT_APP_RMIS_PAYMENT_URL;

export const API_BASE_URL = REACT_APP_API_BASE_URL;
export const API_KEY = REACT_APP_API_KEY;
export const APP_NAME = 'e-ticketing';
export const TABLE_PAGE_SIZE = 10;
export const MEDIA_BASE_URL = REACT_APP_MEDIA_BASE_URL;
export const RECAPTCHA_SITE_KEY = REACT_APP_RECAPTCHA_SITE_KEY;
// export const RECAPTCHA_SECRET_KEY = '6LeFr7EgAAAAACxkfJRstM4cYfFJz92-8899QAd8';
// resend time limit to display the re send button for otp (time in seconds)
export const OTP_RESEND_TIMELIMT = 60;

export const dashboardSchema = {
  header: {
    config: {
      xs: {
        position: 'sticky',
        height: 48,
      },
      sm: {
        position: 'sticky',
        height: 64,
      },
      md: {
        position: 'sticky',
        height: 70,
      },
    },
  },
  subheader: {
    config: {
      xs: {
        position: 'sticky',
        height: 44,
      },
      sm: {
        position: 'sticky',
        height: 44,
      },
      md: {
        position: 'sticky',
        height: 48,
      },
    },
  },
  leftEdgeSidebar: {
    autoCollapse: 'md',
    config: {
      xs: {
        variant: 'temporary',
      },
      sm: {
        variant: 'temporary',
      },
      md: {
        variant: 'permanent',
        width: 250,
        collapsible: true,
        collapsedWidth: 70,
      },
    },
  },
};

export const frontMenu = [
  {
    label: 'Home',
    to: '/',
    key: 1,
  },
  {
    label: 'Find My Ticket',
    to: '/findticket',
    key: 2,
  },
  {
    label: 'FAQ',
    to: '/faq',
    key: 3,
  },
  // {
  //   label: 'Contact',
  //   to: '/contact',
  //   key: 4,
  // },
];

export const schema = {
  header: {
    config: {
      xs: {
        position: 'sticky',
        height: 48,
      },
      sm: {
        position: 'sticky',
        height: 64,
      },
      md: {
        position: 'sticky',
        height: 70,
      },
    },
  },
  topHeader: {
    config: {
      xs: { position: 'relative', clipped: true },
    },
  },
  rightEdgeSidebar: {
    config: {
      xs: {
        variant: 'temporary',
      },
      sm: {
        variant: 'temporary',
        width: 250,
      },
      md: {
        variant: 'temporary',
        width: 250,
      },
    },
  },
};

export const khaltiConfig = {
  // replace this key with yours
  publicKey: KHALTI_TEST_API_KEY,
  paymentPreference: ['KHALTI'],
};
