import React from 'react';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import PageStatus from '../../layouts/PageStatus';

const NotFound = () => (
  <PageStatus
    icon={<ReportGmailerrorredIcon sx={{ fontSize: '1.2em' }} />}
    status="404"
    statusText="Page not found"
    body="Sorry, the page you are searching for is unavailable."
  />
);

export default NotFound;
