import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Login from '../pages/Login';
import Unauthorized from '../pages/dashboard/Unauthorized';
import Loading from '../components/Loading';
import NotFound from '../pages/dashboard/NotFound';

const DashboardRoute = React.lazy(() => import('./DashboardRoute'));
const IndexRoute = React.lazy(() => import('./IndexRoute'));

const RoutePath = () => (
  <Routes>
    <Route
      path="/*"
      element={(
        <React.Suspense fallback={<Loading />}>
          <IndexRoute />
        </React.Suspense>
      )}
    />
    <Route
      path="dashboard/*"
      element={(
        <React.Suspense fallback={<Loading />}>
          <DashboardRoute />
        </React.Suspense>
      )}
    />
    <Route path="login" element={<Login />} />
    <Route path="unauthorized" element={<Unauthorized />} />
    <Route path="*" element={<NotFound />} />
  </Routes>
);

export default RoutePath;
