import {
  TextField,
} from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
/* eslint-disable */

export default function ControllerTextField({
  control,
  name,
  rules,
  defaultValue,
  label,
  variant,
  id,
  type,
  select,
  options,
  helperText,
  multiline,
  rows,
  ...rest
}) {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={defaultValue}
      render={({
        field: { onChange, value, onBlur, ref },
        fieldState: { invalid, error },
      }) =>
        select ? (
          <TextField
            error={invalid}
            onBlur={onBlur}
            inputRef={ref}
            value={value}
            onChange={onChange}
            label={label}
            variant={variant}
            id={id}
            fullWidth
            select
            margin='normal'
            helperText={error?.message || helperText}
            {...rest}
          >
            {options}
          </TextField>
        ) : (
          <TextField
            error={invalid}
            onBlur={onBlur}
            inputRef={ref}
            value={value}
            onChange={onChange}
            label={label}
            variant={variant}
            id={id}
            fullWidth
            type={type}
            margin='normal'
            multiline={multiline}
            rows={rows}
            helperText={error?.message || helperText}
            {...rest}
          />
        )
      }
    />
  );
}

ControllerTextField.propTypes = {
  control: PropTypes.objectOf(PropTypes.any).isRequired,
  name: PropTypes.string.isRequired,
  rules: PropTypes.objectOf(PropTypes.any),
  defaultValue: PropTypes.string,
  label: PropTypes.string,
  variant: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
  select: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.element),
  helperText: PropTypes.string,
};

ControllerTextField.defaultProps = {
  rules: {},
  defaultValue: '',
  label: '',
  variant: 'outlined',
  id: '',
  type: 'text',
  select: false,
  options: [],
  helperText: null,
};
