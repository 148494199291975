import React, {
  createContext, useCallback, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { requests } from '../API';

export const AuthContext = createContext(null);
const AuthProvider = ({ children }) => {
  const [currentStorage, SyncWithLocalStorage] = useState(localStorage || {});
  const [user, setUser] = useState(() => JSON.parse(localStorage.getItem('user')));
  const [isAuthenticated, setIsAuthenticated] = useState(
    () => Boolean(localStorage.getItem('user')),
  );
  const navigate = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const from = location.state?.from?.pathname || '/dashboard';
  const login = (email, password) => requests.post('/account/login/', { email, password }).then((res) => {
    sessionStorage.setItem('token', res.access_token);
    localStorage.setItem('refresh', res.refresh_token);
    localStorage.setItem('user', JSON.stringify(res.user));
    setUser(res.user);
    setIsAuthenticated(true);
    navigate(from, { replace: true });
    enqueueSnackbar('You are logged in successfully.', { variant: 'success' });
    return res;
  });

  const syncUser = (user) => {
    localStorage.setItem('user', JSON.stringify(user));
    setUser(user);
    // return response;
  };

  const logout = () => {
    localStorage.removeItem('user');
    sessionStorage.removeItem('token');
    localStorage.removeItem('refresh');
    navigate('/login', { replace: true });
    setUser({});
    setIsAuthenticated(false);
    enqueueSnackbar('You are logged out successfully.', { variant: 'success' });
    Promise.resolve({ accessToken: null, user: {} });
  };

  const eventListenerFun = useCallback(() => {
    SyncWithLocalStorage({ ...localStorage });
    if (currentStorage.user) {
      setUser(JSON.parse(currentStorage.user));
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, [currentStorage.user]);

  useEffect(() => {
    window.addEventListener('storage', eventListenerFun);
    return () => window.removeEventListener('storage', eventListenerFun);
  }, [eventListenerFun]);

  return (
    <AuthContext.Provider
      value={{
        user,
        syncUser,
        login,
        logout,
        isAuthenticated,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default AuthProvider;
