import React from 'react';
import { Typography, Grid } from '@mui/material';
import { ThreeDots } from 'react-loader-spinner';
import theme from '../theme';

const Loading = () => (
  <>
    <Grid
      container
      direction="column"
      p={2}
    >
      <Grid item>
        <Typography variant="h6">Please Wait...</Typography>
        <ThreeDots height="50" width="50" color={theme.palette.text.green} ariaLabel="loading" />
      </Grid>
    </Grid>
  </>
);

Loading.propTypes = {};

export default Loading;
