import { useForm } from 'react-hook-form';
import {
  Grid,
  Paper,
  Typography,
  Avatar,
  CssBaseline,
} from '@mui/material';
import LockOutlined from '@mui/icons-material/LockOutlined';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import ControllerTextField from '../components/ControllerTextField';
import useAuth from '../Hooks/useAuth';
import SubmitButton from '../components/Buttons/SubmitButton';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  size: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    margin: theme.spacing(4, 6),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    marginTop: theme.spacing(1),
  },

  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const Login = () => {
  const classes = useStyles();
  const auth = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const {
    handleSubmit, control, setValue, formState: { isSubmitting },
  } = useForm();
  const onSubmit = async (data, e) => {
    try {
      e.preventDefault();
      await auth.login(data.email, data.password);
    } catch (error) {
      const { response: { data: { message } } } = error;
      enqueueSnackbar(message, { variant: 'error' });
      setValue('password', '');
    }
  };

  return (
    <Grid container component={Paper} className={classes.root}>
      <CssBaseline />
      <Grid
        className={classes.size}
        item
        xs={12}
        sm={8}
        md={5}
        component={Paper}
        elevation={2}
        square
      >
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlined />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
            <ControllerTextField
              control={control}
              name="email"
              label="email"
              type="email"
              id="email"
            />
            <ControllerTextField
              control={control}
              name="password"
              label="password"
              type="password"
              id="password"
            />
            <SubmitButton
              submitText="Login"
              submittingText="Logging in"
              submitting={isSubmitting}
              sx={{ mt: 2 }}
            />
          </form>
        </div>
      </Grid>
    </Grid>
  );
};

export default Login;
