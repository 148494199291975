import { createTheme } from '@mui/material/styles';
import {
  green, blueGrey, grey, indigo,
} from '@mui/material/colors';

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: green[800],
    },
    secondary: indigo,
    text: {
      light: grey[50],
      dark: blueGrey[900],
      green: green[300],
    },
    background: {
      light: green[50],
      dark: green[800],
      white: grey[50],
      grey: grey[200],
    },
    shadow: {
      light: grey[300],
    },
  },
});

export default theme;
