import React from 'react';
import {
  Alert,
  Container, Typography, useMediaQuery,

} from '@mui/material';
import PropTypes from 'prop-types';
import theme from '../theme';

const PageStatus = ({
  icon, status, statusText, body, button, alertProps,
}) => {
  const mdAndDown = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <Container
        icon={false}
        component={Alert}
        sx={{
          p: mdAndDown ? 3 : 4, lineHeight: 2, width: mdAndDown ? '100%' : '40%', textAlign: 'center',
        }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...alertProps}
      >
        <>
          <Typography variant="h2" fontWeight="bold" color="warning.main">{icon}</Typography>
          <Typography variant="h3" fontWeight="bold">{status}</Typography>
          <Typography variant="h4">{statusText}</Typography>
          <Typography variant="body1">{body}</Typography>
          {button}
        </>
      </Container>
    </>
  );
};

PageStatus.propTypes = {
  icon: PropTypes.element.isRequired,
  status: PropTypes.string,
  statusText: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  button: PropTypes.element,
  alertProps: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.any])),
};
PageStatus.defaultProps = {
  button: <></>,
  status: '',
  alertProps: {},
};
export default PageStatus;
