import { Button, CircularProgress } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

function SubmitButton({
  submitting, submittingText, submitText, ...rest
}) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Button type="submit" variant="contained" disabled={submitting} {...rest}>
      {submitting
        ? (
          <>
            <CircularProgress size={20} sx={{ mr: 1 }} />
            {' '}
            {submittingText}
          </>
        )
        : submitText }
    </Button>
  );
}

SubmitButton.propTypes = {
  submitting: PropTypes.bool.isRequired,
  submitText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  submittingText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

SubmitButton.defaultProps = {
  submitText: 'Submit',
  submittingText: 'Submitting',
};

export default SubmitButton;
