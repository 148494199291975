import axios from 'axios';

import { API_BASE_URL, API_KEY } from '../config';
import authHeader from '../services/authHeader';

const API = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true,
  headers: {
    'x-api-key': API_KEY,
  },
});

export const authAPI = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true,
  headers: {
    'x-api-key': API_KEY,
  },
});

export const getData = (res) => res.data;

export const publicRequests = {
  post: (url, data, config) => API.post(url, data, config).then(getData),
  get: (url, config) => API.get(url, config).then(getData),
};

export const requests = {
  delete: (url) => authAPI.delete(url).then(getData),
  get: (url, config) => authAPI.get(url, config).then(getData),
  put: (url, data, config) => authAPI.put(url, data, config).then(getData),
  post: (url, data, config) => authAPI.post(url, data, config).then(getData),
  patch: (url, data, config) => authAPI.patch(url, data, config).then(getData),
};

const refresh = async () => {
  try {
    const response = await API.post('/api/token/refresh/', { refresh: localStorage.getItem('refresh') });
    const token = response.data.access;
    sessionStorage.setItem('token', token);
    return token;
  } catch (error) {
    if (error?.response?.status === 401) {
      sessionStorage.removeItem('token');
      localStorage.removeItem('user');
      localStorage.removeItem('refresh');
      window.dispatchEvent(new Event('storage'));
    }
    return '';
  }
};

authAPI.interceptors.request.use(
  (config) => {
    const newConfig = { ...config };
    newConfig.headers = {
      ...config.headers,
      ...authHeader(sessionStorage.getItem('token')),
    };
    return newConfig;
  },
  (error) => Promise.reject(error),
);

authAPI.interceptors.response.use(
  (response) => response,
  async (error) => {
    const prevRequest = error?.config;
    if (error?.response?.status === 401 && !prevRequest?.sent) {
      prevRequest.sent = true;
      const newAccessToken = await refresh();
      prevRequest.headers = { ...prevRequest.headers, ...authHeader(newAccessToken) };
      return authAPI(prevRequest);
    }
    return Promise.reject(error);
  },
);
