import React from 'react';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import PageStatus from '../../layouts/PageStatus';

const Unauthorized = () => (
  <PageStatus
    icon={<BlockOutlinedIcon sx={{ fontSize: '1.2em' }} />}
    status="401"
    statusText="Unauthorized Access"
    body="Sorry, you donot have permission to view this page."
    alertProps={{
      severity: 'warning',
    }}
  />
);

export default Unauthorized;
